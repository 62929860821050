/**
 * @file Styles des boutons
 * @author Christine Daneau Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Boutons principaux
 * Liens
 * Bouton du menu
 * -- States - État variés
 * -- Rond en arrière
 * Animations
 */

.bouton {
  position: relative;
  transition: all .18s ease-in-out;
  display: inline-block;
  border-radius: 22px;
  padding: 0 20px;
  height: 44px;
  @include fontSize(13px);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 40px;
  color: $marine;
  background-size: 200%;
  z-index: 0;

  &:before {
    content: '';
    transition: opacity .18s ease-in-out;
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    opacity: 0;
  }

  &-tx {
    z-index: 1;
  }

  &:hover, &:focus {
    color: $blanc;
    @include shadow--text();
    background-position: top;

    &:before {
      opacity: 1;
    }
  }

  &:disabled {
    filter: saturate(0);
    opacity: .75;
    pointer-events: none;
  }

  &--primaire {
    @extend .bouton;
    border: 2px solid $orange;
    background: linear-gradient(transparent 50%, transparentize($orangeFonce, .85) 0);
    &:before {
      background: linear-gradient($orange 50%, $orangeFonce 0);
    }
  }

  &--secondaire {
    @extend .bouton;
    border: 2px solid $marine;
    background: linear-gradient(transparent 50%, transparentize(#1F2D4D, .85) 0);
    &:before {
      background: linear-gradient($marine 50%, #1F2D4D 0);
    }
  }

  &--terciaire {
    @extend .bouton;
    color: $blanc;
    border: 2px solid $marine;
    background: linear-gradient(transparent 50%, transparentize(#1F2D4D, .85) 0);
    &:before {
      background: linear-gradient($marine 50%, #1F2D4D 0);
      opacity: 1;
    }

    &:hover, &:focus {
      color: $marine;

      &:before {
        opacity: 0;
      }
    }
  }

  &--ligne {
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 0;
      background: currentColor;
      transition: height .18s ease-in-out;
      z-index: 0;
    }

    &:hover, &:focus, &.active {
      &:after {
        height: 3px;
      }
    }
  }
}


/*--------------------------------------*/
/* Bouton du menu */
/*--------------------------------------*/
.menuBtn {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 13px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover, &:focus {
    outline: 0;
  }

  &__icone{
    position: relative;
    width: 26px;
    height: 20px;
    text-align: center;
    transform: translateY(0);
  }

  &__ligne {
    position: absolute;
    width: 100%;
    height: 2px;
    background: $orange;
    transform-origin: center;
    top: 0;
  }

  // Base state
  &.base {
    .menuBtn__ligne {
      &--1 {
        transform: translateY(0);
      }

      &--2 {
        transform: translateY(10px);
      }

      &--3 {
        transform: translateY(20px);
      }
    }
  }
  // Neutral X state
  &.X {
    .menuBtn__ligne {
      &--1 {
        transform: translateY(10px) rotate(45deg);
      }

      &--2 {
        transform: translateY(10px) scaleX(0);
      }

      &--3 {
        transform: translateY(10px) rotate(-45deg);
      }
    }
  }
  // Open state
  &.open {
    .menuBtn__ligne {
      @for $i from 1 through 3 {
        &--#{$i} {
          animation: animLigne#{$i} .45s ease-in-out both;
        }
      }
    }
  }
  // Close state
  &.close {
    .menuBtn__ligne {
      @for $i from 1 through 3 {
        &--#{$i} {
          animation: animLigne#{$i} .45s ease-in-out reverse both;
        }
      }
    }
  }
}

/* Animations */
/*--------------------------------------*/
@keyframes animLigne1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}

@keyframes animLigne2 {
  0%, 50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(10px) scaleX(0);
  }
}

@keyframes animLigne3 {
  0% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(10px) rotate(-45deg);
  }
}