/**
 * @file Styles - Page Accueil
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Styles généraux
 *
 * Banniere
 *
 * Services de l'accueil
 * --- Les 4 réalisations
 * --- Icône plus
 * --- Les étiquettes pour chaque réalisation
 *
 * Partenaires
 * --- rincipal (en gros)
 * --- Label "Partenaire Principal"
 *
 * Témoignages
 */

// ------------------------------------------------------
// Styles généraux
// ------------------------------------------------------
.conteneur--lien {
  text-align: center;

  @media (min-width: $bureau) {
    text-align: right;
  }
}

// ------------------------------------------------------
// Bannière
// ------------------------------------------------------
.banniere {
  &__liste {
    margin: 0;
  }

  &__picture {
    display: block;
    max-height: 80vh;
    overflow: hidden;
  }
}

.blockTitre {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 14vw auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-family: $fonteTerciaire;

  @media (min-width: $bureau) {
    margin: 6vw auto;
  }

  @media (min-width: $grand) {
    margin: 10vw auto;
  }

  &__logo {
    text-align: center;
    background: rgba($blanc, .75);
    padding: 20px;

    img {
      height: 120px;

      @media (min-width: $grand) {
        height: 200px;
      }
    }
  }

  &__tx {
    @include carte();
    @include shadow--text();
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
  }

  &__titre {
    font-weight: 700;

    @media (min-width: $grand) {
      @include fontSize(28px);
      margin: 20px 12px;
    }
  }

  &__sousTitre {
    @include fontSize(14px);
    letter-spacing: .4px;
    font-weight: 500;
  }
}

// ------------------------------------------------------
// quickView
// ------------------------------------------------------
.quickView {
  transform: translateY(-6rem);

  @media (min-width: $grand) {
    transform: translateY(-9rem);
  }

  &__liste {
    @media (min-width: $grand) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }

    @media (min-width: $large) {
      gap: 32px;
    }
  }

  &__item {
    @include carte();
    margin-bottom: 4rem;

    @media (min-width: $bureau) and (max-width: $grand) {
      width: span(8 of 12);
      margin: 2rem auto 4rem;
    }
  }

  &__titre {
    @include fontSize(24px);
    margin: 0;
    width: 75%;

    @media (min-width: $bureau) {
      @include fontSize(28px);
      width: auto;
    }
  }

  &__flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: $bureau) {
      justify-content: center;
    }

    @media (min-width: $grand) {
      flex-flow: column nowrap;
      margin-bottom: 10px;
    }
  }

  &__tx {
    margin-top: 0;

    @media (min-width: $bureau) {
      text-align: center;
    }

    @media (min-width: $grand) {
      text-align: left;
    }
  }

  .icone {
    width: 85px;
    height: 85px;
    margin-right: 20px;

    @media (min-width: $grand) {
      margin-right: 0;
      width: 140px;
      height: 120px;
    }
  }
}

// ------------------------------------------------------
// Services de l'accueil
// ------------------------------------------------------
.servicesAccueil {
  position: relative;
  transform: translateY(-6rem);

  @media (min-width: $bureau) {
    margin: 80px 0 40px;
  }

  @media (min-width: $grand) {
    transform: translateY(-9rem);
  }

  &__titre {
    text-align: right;
    padding: 2rem 0 5rem;
    position: relative;

    @media (min-width: $bureau) and (max-width: $medium) {
      max-width: 50vw;
      margin-left: auto;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 3rem;
      right: 0;
      height: 4px;
      width: 120px;
      background: $orange;
    }
  }

  &__picture {
    position: relative;
    display: block;
    width: 80%;
    margin-bottom: 90px;

    @media (min-width: $bureau) {
      position: absolute;
      width: 35vw;
      top: 0;
    }

    @media (min-width: $large) {
      width: 30vw;
      max-width: 500px;
    }

    &:before, &:after {
      content: '';
      position: absolute;
    }

    &:before {
      right: -32px;
      bottom: -32px;
      width: 80%;
      height: 80%;
      background: rgba($orange, .35);
    }

    &:after {
      right: -25%;
      bottom: -64px;
      width: 60%;
      height: 60%;
      background: $wildSand;
      z-index: -1;
    }
  }

  &__tx {
    margin: 4rem 0;

    @media (min-width: $bureau) {
      text-align: right;
      width: 50vw;
      margin-left: auto;
      margin-top: 0;
    }

    @media (min-width: $large) {
      width: 45vw;
    }
  }

  &__liste {
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;

    &::-webkit-scrollbar {
      width: 0;
    }

    @media (max-width: $bureau) {
      overflow-x: scroll;
    }

    @media (min-width: $bureau) {
      justify-content: flex-end;
      margin-bottom: 32px;
    }
  }

  &__item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    &_tx {
      width: max-content;
      padding: 5px 0;
    }
  }

  &__icone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: $marine;
    background: $mercury;

    svg {
      width: 40px;
      height: 40px;
    }
  }
}

// ------------------------------------------------------
// Rénovations / Réalisations de l'accueil
// ------------------------------------------------------
.reno {
  background: $wildSand;
  padding: 4rem 0;

  @media (min-width: $bureau) {
    padding: 8rem 0;
  }

  @media (min-width: $grand) {
    padding: 12rem 0;
  }

  &__h2 {
    margin: 0;
    padding: 0 0 4rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: calc(1rem - 5px);
      height: 10px;
      width: 200px;
      background-image: url("../images/point.svg"),  url("../images/point.svg"),  url("../images/point.svg");
      background-position: 0, 40px, 80px;
      background-repeat: no-repeat;
    }
  }

  &__h3 {
    margin: 0;
    height: -webkit-fill-available;

    @media (min-width: $bureau) {
      @include fontSize(20px);
    }

    @media (min-width: $large) {
      @include fontSize(24px);
    }
  }

  // Les 4 réalisations
  &__liste {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    grid-gap: 4vw;
    margin: 4rem 0;

    @media (min-width: $medium) {
      grid-template: 1fr / repeat(4, 1fr);
    }
  }

  &__item {
    position: relative;
    overflow: hidden;

    &_info {
      transition: top .25s ease-in-out;
      position: absolute;
      top: 100%;
      width: 100%;
      height: 100%;
      padding: 14px;
      background: transparentize($orange, .5);
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      z-index: 1;
      pointer-events: none;

      @media (min-width: $tablette) {
        padding: 30px;
      }
    }

    &:hover, &:focus {
      cursor: pointer;

      .reno__item_info {
        top: 0;
      }

      .reno__img {
        opacity: .5;
      }
    }
  }

  // Image
  &__img {
    transition: opacity .2s ease-in-out;
  }

  // Les étiquettes pour chaque réalisation
  &__tags {
    @include fontSize(12px);
    font-weight: 500;
    line-height: 1.25;

    @media (min-width: $tablette) {
      @include fontSize(14px);
    }

    @media (min-width: $bureau) {
      line-height: 1.5;
    }

    &_item {
      display: inline;
    }
  }

  // Icône plus
  .icone--plus {
    margin: 0 0 10px;

    @media (min-width: $tablette) and (max-width: $medium) {
      margin: 20px 0;
    }
  }

  // Bouton page
  .bouton--primaire {
    margin-top: 10px;
  }
}

// ------------------------------------------------------
// Partenaires
// ------------------------------------------------------
.partenaires {
  position: relative;
  margin: 7rem 0;

  @media (min-width: $bureau) {
    margin: 8rem 0 4rem;
  }

  @media (min-width: $grand) {
    margin: 8rem 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 12px;
    height: 150px;
    width: 120px;
    background-image: url("../images/point.svg"),  url("../images/point.svg"),  url("../images/point.svg"),  url("../images/point.svg");
    background-position:0 0, 0 25%, 0 50%, 0 75%;
    background-repeat: no-repeat;
    filter: brightness(0.95);
    z-index: 1;

    @media (min-width: $mobile) {
      left: 16px;
    }

    @media (min-width: $bureau) {
      left: 26px;
      top: 9vw;
    }

    @media (min-width: $medium) {
      top: 38px;
    }

    @media (min-width: 1264px) {
      width: 1240px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    @media (min-width: $large) {
      top: 54px;
      height: 200px;
    }
  }

  &__flex {
    @media (min-width: $bureau) {
      display: grid;
      grid-template-columns: 1fr 50%;
      align-items: flex-end;
      gap: 40px;
    }

    &_item {
      z-index: 0;
    }
  }

  &__img {
    border: 4px solid $mercury;
  }

  &__titre, &__sous-titre, &__desc {
    padding-left: 20px;
  }

  &__sous-titre, &__desc {
    @media (min-width: $bureau) {
      padding-left: 0;
    }
  }

  &__sous-titre {
    @media (max-width: $bureau) {
      margin-top: 48px;
    }
  }

  // Principal (en gros)
  &__picture--big {
    position: relative;
    display: block;
    margin-bottom: 30px;
    padding-left: 20px;
    width: 100%;
    height: 100%;

    @media (min-width: $bureau) {
      height: auto;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      background: $wildSand;
      z-index: -1;
    }

    &:before {
      width: 240px;
      height: 56vw;
      top: -80px;
      left: -6vw;

      @media (min-width: $bureau) {
        top: -90px;
        left: -60px;
        width: 300px;
        height: 100%;
      }

      @media (min-width: $large) {
        top: -150px;
        width: 430px;
      }
    }

    &:after {
      width: 100px;
      height: 100px;
      bottom: -10px;
      right: -20px;

      @media (min-width: $mobile) {
        bottom: -20px;
      }
    }
  }

  &__img--big {
    border: 3px solid $orange;
  }

  // Label "Partenaire Principal"
  &__PPlabel {
    position: absolute;
    top: 59vw;
    display: inline-block;
    margin-left: 40px;
    padding: 5px 10px;
    background: $wildSand;
    border: 1px solid $mercury;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15);

    @media (min-width: $mobile) {
      top: 56.5vw;
    }

    @media (min-width: $bureau) {
      top: initial;
      bottom: 12px;
    }
  }

  &__nom {
    text-align: center;
    @include fontSize(13px);
    line-height: 1.25;
    font-weight: 700;

    @media (min-width: $bureau) {
      @include fontSize(14px);
    }
  }
}

// ------------------------------------------------------
// Témoignages
// ------------------------------------------------------
.temoignages {
  background: $wildSand;

  &__item_carte {
    background-color: $blanc;
    background-image: url("../images/quotes2.svg"),  url("../images/quotes.svg");
    background-position: 10px 10px, calc(100% - 10px) calc(100% - 10px);
    background-repeat: no-repeat;
    background-size: 60px;
    border-radius: 2px;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 2px 18px -10px rgba(0, 0, 0, 0.25), 0 1px 10px rgba(0,0,0,0.1);

    @media (min-width: $bureau) {
      background-size: initial;
      padding: 60px 100px;
      background-position: 36px 20px, calc(100% - 36px) calc(100% - 20px);
    }

    @media (min-width: $medium) {
      max-width: span(8 of 12);
      margin: 20px auto;
    }
  }

  p {
    margin: 2px 0;
  }

  &__flex {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__img {
    margin: 0 10px 5px 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  &__nom {
    @include fontSize(18px);
    font-family: $fonteTerciaire;
    font-weight: bold;
  }

  &__titre {
    opacity: .75;
    font-weight: 300;
  }

  // GLIDE //
  &__liste {
    align-items: center;
  }

  .glide__arrow {
    top: calc(50% - 20px);

    @media (min-width: $bureau) {
      top: calc(50% - 40px);
    }


    &--left {
      @media (min-width: $medium) {
        left: span(1.25 of 12);
      }
    }

    &--right {
      @media (min-width: $medium) {
        right: span(1.25 of 12);
      }
    }
  }
}