/**
 * @file Styles - Page réalisations
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Page layout
 * Accordeon
 * Filtres
 * --- checkbox
 * --- switch
 * --- reset
 * Résultats
 * Overlay
 * --- image
 * --- texte
 * --- animation
 * --- bouton X - ajustement
 */

// Page layout
// ------------------------------------------------------
.realisations {
  @media (min-width: $bureau) {
    margin: 8rem 0 0;
  }

  &__conteneur {
    @media (min-width: $grand) {
      display: grid;
      grid-template-columns: 320px 1fr;
      gap: 40px;
      padding: 40px 0;
    }
  }
}

// Accordeon
// ------------------------------------------------------
.accordeon {
  overflow: hidden;
  transition: all .4s ease-in-out;

  &:nth-of-type(1) {
    height: 268px;
  }

  &:nth-of-type(2) {
    height: 182px;
  }

  &:nth-of-type(3) {
    height: 302px;
  }

  .icone {
    transition: all .25s ease-in-out;
    transform: rotate(180deg);
  }

  &.accordeon--fermer {
    height: 46px;

    .icone {
      transform: rotate(0);
    }
  }
}

// Filtres
// ------------------------------------------------------
.filtres {
  @media (min-width: $grand) {
    padding-top: 4px;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 2rem 0;
  }

  legend {
    @include titreForm();
    border-bottom: 2px solid $mercury;
    transition: all .18s ease-in-out;

    &:hover {
      border-color: rgba($marine, .7);
    }
  }

  span {
    display: block;
    margin: 16px 0;
    width: fit-content;

    &:hover, &:focus {
      cursor: pointer;
    }
  }

  &__flex {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}

// Type - boîte à cocher
.checkbox {
  label {
    position: relative;
    display: inline-block;
    width: fit-content;
    padding-left: 30px;
    line-height: 20px;

    &:before , &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      border: 2px solid;
      border-radius: 3px;
      transition: all .18s ease-in-out;
    }

    &:before {
      border-color: $lilas;
    }

    &:after {
      border-color: transparent;
    }

    &:hover, &:focus {
      cursor: pointer;
      transition: all .18s ease-in-out;

      &:before {
        border-color: rgba($marine, .7);
      }
    }
  }

  input:checked {
    ~ label {

      &:before {
        border: 2px solid $orangeFonce;
        background: $orange;
      }

      &:after {
        background: url("../images/crochet.svg") no-repeat center / contain;
      }
    }
  }
}

// Type - Switch on off
.switch {
  position: relative;

  label {
    @include titreForm();
    padding-left: 50px;

    &:before , &:after {
      transition: all .18s ease-in-out;
      content: '';
      position: absolute;
      border-radius: 20px;
      left: 0;
    }

    &:before {
      width: 20px;
      height: 20px;
      border: 2px solid $lilas;
      background: $alabaster;
      z-index: 2;
      box-shadow: 0px 0px 1px rgba(0,0,0,0.5), 0px 1px 4px rgba(0,0,0,0.25);
    }

    &:after {
      margin: 3px;
      width: 34px;
      height: 14px;
      background: $mercury;
      z-index: 1;
    }

    &:hover, &:focus {
      cursor: pointer;
      transition: all .18s ease-in-out;

      &:before {
        border-color: rgba($marine, .7);
      }
    }
  }

  input:checked {
    ~ label {
      &:before {
        left: 18px;
        border: 2px solid $orange;
      }

      &:after {
        background: transparentize($orangeFonce, .8);
        filter: brightness(.8);
      }
    }
  }
}

// Type - Réinitialiser les filtres
.reset {
  display: block;
  width: 44px;
  height: 44px;

  .bouton--secondaire:before {
    opacity: 0;
  }

  .bouton--secondaire:hover {
    color: $marine;
  }

  .icone {
    transition: all .25s ease-in-out;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    padding-right: 2px;
    transform: rotate(-180deg);
  }

  label {
    &:hover, &:focus {
      cursor: pointer;

      .icone {
        transform: rotate(-360deg);
      }
    }
  }
}

.envoyer {
  margin-right: 20px;
}

// Résultats - grille d'images
// ------------------------------------------------------
.resultats {
  .reno {
    &__liste {
      @media (min-width: $tablette) {
        grid-template: 1fr / repeat(3, 1fr);
        gap: 20px;
      }

      @media (min-width: $bureau) and (max-width: $grand) {
        grid-template: 1fr / repeat(4, 1fr);
      }
    }

    &__item_info {
      @media (min-width: $tablette) and (max-width: $grand) {
        padding: 16px;
      }
    }
  }
}


// Overlay
// ------------------------------------------------------
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $noir\.35;
  z-index: 10;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  transition: background-color .4s ease-in-out;

  // animation
  &.close {
    pointer-events: none;
    background-color: transparent;
    transition: background-color .4s ease-in-out .75s;

    .conteneur {
      transition: transform .85s cubic-bezier(.60, 0, .18, 1) 0s, opacity .4s ease-in-out .35s;
      transform: translateY(40px);
      opacity: 0;
    }
  }


  .conteneur {
    display: flex;
    justify-content: center;
    transform: translateY(0);
    opacity: 1;
    transition: transform .85s cubic-bezier(.60, 0, .18, 1) .2s, opacity .4s ease-in-out .4s;
  }

  &__inner {
    display: flex;
    flex-flow: column nowrap;
    margin: 20px 0;
  }

  // image
  &__image {
    @media (min-width: $medium) {
      width: 100%;
      height: 100%;
      max-height: 75vh;
    }

    img {
      max-height: 80vh;

      @media (min-width: $medium) {
        width: auto;
        max-height: inherit;
      }
    }
  }

  // texte
  &__texte {
    margin: 20px 0 0;
    border-top: 2px solid $blanc;
    padding: 10px 0;
    color: $blanc;
    text-shadow: 1px 1px 8px black, 1px 1px 3px black, 1px 1px black;
    letter-spacing: 0.5px;

    @media (max-height: $tablette) {
      display: none;
    }
  }
  // bouton X - ajustement
  .icone--x {
    position: relative;
    filter: drop-shadow(0px 1px 2px $noir\.35); // saturate(0)
    //transition: filter .4s cubic-bezier(.40, 0, .18, 1);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border-radius: 50%;
      border: 2px solid $orange;
      width: 35px;
      height: 35px;
      transform-origin: center;
      transform: translate(-10px, 0) scale(0);
      transition: transform .4s cubic-bezier(.40, 0, .18, 1);
    }

    &:hover, &:focus {
      //filter: drop-shadow(0px 1px 2px $noir\.35);
      &:before {
        transform: translate(-10px, 0) scale(1);
      }
    }
  }
}