/**
 * @file Styles de l'en-tête
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ---------------------------------------------------------
 * TABLE DES MATIÈRES - En-tête
 * ---------------------------------------------------------
 * STYLES COMMUNS
 *
 * LOGO - lien accueil
 * MENU - primaire
 * MENU - secondaire
 * ANIMATIONS
 */

/*-----------------------------------*/
/* STYLES COMMUNS */
/*-----------------------------------*/
.entete {
  position: relative;
}

.navigation--conteneur {
  background: $marine;
  border: 2px solid $orangeFonce;
  box-shadow: 0 0 6px #00000090, 0 0 16px #00000050, inset 0px 0px 6px 0 $noir\.5;
  border-right: none;
  border-left: none;
  transition: all .3s ease-in-out;
  width: 100%;
  height: 352px;

  @media (min-width: $tablette) {
    height: 370px;
  }

  @media (min-width: $bureau) {
    height: auto;
    border: none;
  }

  @media (min-width: $grand) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

/*-----------------------------------*/
/* LOGO - lien accueil */
/*-----------------------------------*/
.entete .logo {
  transition: opacity .25s ease-in-out;

  @media (min-width: $bureau) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__img {
    width: fit-content;
    height: 40px;
    margin: 10px 20px;
    transform: translateY(8px);

    @media (min-width: $bureau) {
      transform: translateY(-2px);
      margin: 20px;
    }
  }

  &__tx {
    @include fontSize(26px);
    color: $txLight;
    font-family: $fonteSecondaire;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: none;

    @media (min-width: $grand) and (max-width: 1300px) {
      @include fontSize(20px);
    }

    @media (min-width: $bureau) {
      display: initial;
    }
  }
}


/*-----------------------------------*/
/* MENU - primaire */
/*-----------------------------------*/
.menu {
  transition: all .3s ease-in-out;

  &__liste {
    padding: 10px 0;
    border: 2px solid #485267;
    border-right: none;
    border-left: none;
    box-shadow: 0 0 8px $noir\.5, inset 0 0 8px 0 $noir\.5;

    @media (min-width: $bureau) {
      display: flex;
      flex-flow: row wrap;
      margin: 0;
      padding: 10px 4px;
    }

    @media (min-width: $grand) {
      border: none;
      padding: 0 16px 0 0;
      box-shadow: none;
    }
  }

  &__item {
    transition: all .3s ease-out;

    @for $i from 1 through 4 {
      &:nth-of-type(#{$i}) {
        transition-delay: calc(#{$i} * 0.05s);
      }
    }
  }

  &__item_lien {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    color: $mercury;
    font-weight: 400;
    letter-spacing: .75px;
    text-transform: uppercase;

    @media (min-width: $grand) {
      font-weight: 500;
    }
  }

  .icone {
    margin: 0 10px 3px 0;
  }

  .icone__conteneur.active {
    @media (min-width: $grand) {
      background: $orangeFonce;
      height: 80px;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      margin: auto;
      clip-path: polygon(0px 100%, 20px 0%, 100% 0px, calc(100% - 20px) 100%);

      svg {
        opacity: 0;
      }
    }
  }
}

/*-----------------------------------*/
/* MENU - secondaire */
/*-----------------------------------*/
.subnav {
  .icone {
    width: 26px;
    height: 26px;
  }

  &__liste {
    display: flex;
    flex-flow: row nowrap;
    margin: 24px 10px;
    height: 26px;
  }

  &__item {
    transition: all .3s ease-in-out;

    @for $i from 1 through 4 {
      &:nth-of-type(#{$i}) {
        transition-delay: calc(#{$i} * 0.05s + 0.18s);
      }
    }
  }

  &__lien {
    display: inline-block;
    padding: 0 10px;
    height: 26px;
  }
}


/*-----------------------------------*/
/* ANIMATIONS */
/*-----------------------------------*/
@media (max-width: $bureau) {
  .js .navigation--fermer {
    height: 78px;
    width: 80px;
    border-radius: 50% 0 50% 50%;
    border-left: 2px solid $orange;
    border-right: 2px solid $orange;
    transform: translate(4px, -6px);

    .logo {
      opacity: 0;
    }

    //TODO timer les animations avec le vrai css animation
  }
}


/*-----------------------------------*/
/* AVEC JS */
/*-----------------------------------*/
@media (max-width: $bureau) {
  .js .navigation--conteneur {
    // État au mobile + js
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
  }

  .js .navigation--fermer.cacherNav {
    transform: translate(100px, -60px);
  }
}