/**
 * @file Styles des variables
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
 *
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Polices de caractères
 * Palette de couleurs
 * Points de rupture
 **/

/** Polices de caractères **/
/***************************/

/** Importation des fonts **/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//@font-face {
//  font-family: 'Book Antiqua';
//  src: url("../fonts/ANTQUAB.woff");
//  font-weight: 900;
//}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: 'Book Antiqua';
  src: url("../fonts/ANTQUAB.woff");
  font-weight: 900;
}

/** Polices de caractères **/
/***************************/
$fontePrimaire: 'Open Sans', sans-serif;
$fonteSecondaire: 'Book Antiqua', sans-serif;
$fonteTerciaire: 'Poppins', 'Open Sans', sans-serif;

/** Palette de couleurs **/
/*************************/
$charcoal: #202020;
$noir: #000;
$noir\.5: transparentize($noir, .5);
$noir\.35: transparentize($noir, .35);
$marine: #14213D;
$marine\.78: lighten($marine, 78);
$bleu: #203665;
$lilas: #ACBBDA;
$mercury: #E5E5E5;
$wildSand: #F6F6F6;
$alabaster: #FBFBFB;
$blanc: #fff;
$blanc\.5: transparentize($blanc, .5);
$txLight: $mercury;
$txGray: #A6A6A6;
$orange: #FCA311;
$orangeFonce: #E88B00;
$ocre: #895100;
$erreur: #FF5A26;

/** Points de rupture **/
/***********************/
$huge : 1600px;
$large : 1440px;
$grand : 1180px;
$medium : 1024px;
$bureau : 720px;
$tablette : 480px;
$mobile : 360px;
