/**
 * @author Christine Daneau Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Généralités
 * Titre
 * Display
 * Fix Page contact
 * Glide
 * outline & selection
 * page 404
 * espace pour le menu fixe
 * aller au contenu
 * JavaScript
 * Sociaux
 * Animations d'arrivé
 */

/*--------------------------------------*/
/* Généralités */
/*--------------------------------------*/
html {
  scroll-behavior: smooth;
  overflow-x: hidden;

  &.freeze {
    overflow: hidden;
  }
}

main {
  background: $blanc;
  margin-bottom: 400px;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15vw), 0% 100%);

  @media (min-width: $bureau) {
    margin-bottom: 0;
    clip-path: none;
  }
}

i {
  font-style: italic;
}

a {
  text-decoration: none;
  color: $charcoal;
}

.lien {
}

ul {
  list-style: none;
  padding: 0;
}

img{
  width: 100%;
  vertical-align: bottom;
}

blockquote {
  margin-block-end: 0.5em;
  margin-block-start: 0.5em;
}

// Titres
.titrePage {
  position: relative;
  padding: 30px 0 20px;
  margin: 0 0 24px;

  @media (min-width: $large) {
    margin: 0 0 32px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 4px;
    background: $orange;

    .realisations & {
      width: 300px;
    }

    @media (min-width: $large) {
      width: 280px;

      .realisations & {
        width: 410px;
      }
    }
  }
}

.titreCarte {
  color: $orangeFonce;
}

// Display
.displayNone {
  display: none;
}

.tableOnly {
  @media (max-width: $bureau) {
    display: none;
  }
}
.mobileOnly, .icone.mobileOnly {
  @media (min-width: $bureau) {
    display: none;
  }
}

// Fix Page contact
.page--contact {
  overflow: hidden;
}
//.grecaptcha-badge {
//  transform: translateX(80px);
//  z-index: 2;
//}

/*--------------------------------------*/
/* GLIDE */
/*--------------------------------------*/
.glide {
  &__arrow {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    display: flex;
    filter: drop-shadow(1px 1px 2px #00000045);

    &:hover .icone {
      @media (min-width: $bureau) {
        transform: translateX(10px);
      }
    }

    &--left {
      transform: scale(-1) scaleY(-1);
      left: 0;
    }

    &--right {
      right: 0;
    }

    .icone {
      width: 40px;
      height: 40px;
      transition: transform .2s ease-in-out;

      @media (min-width: $bureau) {
        height: 80px;
      }
    }
  }

  &__bullets {
    position: absolute;
    bottom: 8rem;
    text-align: center;
    width: 100%;

    @media (min-width: $bureau) {
      bottom: 7rem;
    }

    @media (min-width: $grand) {
      bottom: 11rem;
    }
  }

  &__bullet {
    padding: 14px;
    background: none;
    border: none;
    transition: transform .2s ease-in-out;

    &:hover {
      @media (min-width: $bureau) {
        transform: translateY(-6px);
      }
    }

    .point {
      display: block;
      transition: background-color .18s ease-in-out;
      height: 12px;
      width: 12px;
      padding: 0;
      background-color: $blanc;
      border: 1px solid $orangeFonce;
      border-radius: 50%;
      @include shadow--input();
    }

    &.glide__bullet--active {
      .point {
        background-color: $orange;
      }
    }
  }
}


/*--------------------------------------*/
/* outline & selection */
/*--------------------------------------*/
:focus {
  outline: 0;
}

::selection {
background-color: transparentize($bleu, .8);
}

/*--------------------------------------*/
/* page 404 */
/*--------------------------------------*/
.pageErreur {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  &__contenu {
    @media (min-width: $bureau) {
    width: span(5 of 12);
    }
  }

  h1 {
    @include fontSize(40px);
    line-height: 1.5;
    @media (min-width: $bureau) {
      @include fontSize(70px);
    }
  }
}

/*--------------------------------------*/
/* Aller au contenu */
/*--------------------------------------*/
.allerContenu {
  display:block;
  padding: 10px 20px;
  &:focus {
    background: $wildSand;
  }
}

/*--------------------------------------*/
/* Javascript */
/*--------------------------------------*/
.noScript {
  @extend .boiteModale;

  .icone {
    float: left;
    margin-right: 20px;
  }

  @media (min-width: $bureau) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .icone {
      width: 110px;
    }
  }
}

/*--------------------------------------*/
/* Sociaux */
/*--------------------------------------*/
.sociaux {
  .icone {
    width: 34px;
    height: 34px;
  }

  &__conteneur {
    display: flex;
    justify-content: space-between;
  }
}


// ------------------------------------------------------
// Animations d'arrivé
// ------------------------------------------------------
@keyframes animIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes animIn2 {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes animIn--to {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animIn {
  // les 3 services
  .services__item {
    animation: animIn .55s .25s cubic-bezier(0.43, 0, 0.17, 1.75) both;
    &:nth-of-type(2) {
      animation-delay: .5s;
    }
    &:nth-of-type(3) {
      animation-delay: .75s;
    }
  }

  // projets en rafales et les champs de formulaire
  .rafale__item, .ctnForm {
    animation: animIn .55s .25s cubic-bezier(0.43, 0, 0.17, 1.7) both;

    @for $i from 1 through 10 {
      $cptDelay: ($i - 1.25)*.25;
      &--#{$i} {
        animation-delay: $cptDelay+s;
      }
    }
  }

}

.animIn--seul {
  animation: animIn2 .55s cubic-bezier(0.43, 0, 0.17, 1.75) both;
}