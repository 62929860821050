/**
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * UTILITAIRES
 * BASE
 * GRILLE
 * MODULES
 * libraires externes
 * -----------------------------------------------
 */

/*** Librairies ***/
@import '../../../node_modules/susy/sass/_susy.scss';
@import '../../../node_modules/susy/sass/plugins/_svg-grid.scss';
@import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";

/*** Normalize ***/
@import '../../../node_modules/normalize.scss/normalize.scss';

/*** Utilitaires ***/
@import 'utilitaires/variables';
@import 'utilitaires/mixins';

/*** Base ***/
@import 'base/typo';
@import 'base/general';
@import 'base/icones';
@import 'base/boutons';

/*** Grille ***/
@import 'grille/general';
@import 'grille/entete';
@import 'grille/pied';

/*** Modules ***/
@import "modules/accueil";
@import "modules/aporpos";
@import "modules/services";
@import "modules/realisations";
@import "modules/contact";
@import "modules/cta";


