/**
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ---------------------------------------------------------
 * TABLE DES MATIÈRES - Icônes
 * ---------------------------------------------------------
 * Styles généraux
 * source de chaque icône
 */


/*** Styles généraux ***/
.icone {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 0;

  &--btn {
    background: transparent;
    border: 0;


    &:focus {
      outline: 0;
    }
  }

  /*  Boutons d'action */
  &--plus, &--moins, &--haut {
    --taille: 28px;
    @media (min-width: $bureau) {
      --taille: 40px;
    }

    width: var(--taille);
    height: var(--taille);
    border-radius: 50%;
    transition: transform .3s cubic-bezier(.43,0,.17,1.4);

    &:hover, &:focus {
      transform: scale(1.12);
    }

    svg {
      width: var(--taille);
      height: var(--taille);
    }
  }

  &--x {
    background: url(../images/x.svg) no-repeat center/contain;

    .overlay & {
      padding: 20px 0;
      align-self: flex-end;
    }
  }
  &--plus {
    //background: url("../images/icones/plus.svg") no-repeat center / contain;
  }
  &--moins {
    //background: url("../images/icones/moins.svg") no-repeat center / contain;
  }
  &--haut {
    //background: url("../images/icones/top.svg") no-repeat center / contain;
  }

  /* Validation & fomulaires */
  &--info {
    //background: url("../images/icones/info.svg") no-repeat center / contain;
  }
  &--erreur {
    background: url("../images/erreur.svg") no-repeat center / contain;
  }
  &--ok {
    background: url("../images/ok.svg") no-repeat center / contain;
  }

}