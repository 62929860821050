/**
 * @file Styles pour créer des mixins
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
 *
 * ----------------------------
 * TABLE DES MATIÈRES - MIXINS
 * ----------------------------
 * base (BoxSizing, Clearfix)
 * accessibilité
 * typographie
 * shadows
 * boite modale
 * Carte
 * Formulaire
 */



/* Utilitaires de base (BoxSizing, Clearfix) */
/*--------------------------------------*/
/* BoxSizing */
@mixin border-box-sizing{
  * {
    box-sizing: border-box;
  }
}

/* Clearfix: pour contrer le problème des enfants flottants*/
@mixin clearfix {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

.cf{
  @include clearfix;
}

/* Utilitaires pour accessibilité */
/*--------------------------------------*/
// Hide only visually, but have it available for screenreaders:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: https://www.drupal.org/node/897638
  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: normal;
    width: auto;
  }
}
.visuallyhidden,
.screen-reader-only{
  @include visuallyhidden;
}

.displayNone {
  display: none;
}


/* Utilitaires pour typographie */
/*--------------------------------------*/
// Fonction pour convertir une valeur de font-size en pixels à son équivalent rem
@function calculateRem($size) {
  $remSize: $size / 10px;
  @return #{$remSize}rem;
}
// Mixin qui inclus la solution de repli en pixel suivi par la valeur calculée en rem
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/* Utilitaire de mise en page */
@mixin hardReset{
  margin:0;
  padding:0;
}


/** SHADOWS **/
/*--------------------------------------*/
@mixin shadow--text {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
@mixin shadow--input {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
}
@mixin shadow--box {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}



/* Base Modale */
/*--------------------------------------*/
.boiteModale {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border: 16px solid $wildSand;
  border-radius: 10px;
  max-width: 450px;
  height: fit-content;
  padding: 20px;
  background: $blanc;
  box-shadow: 0 1px 4px 0px rgba(0,0,0,0.2),0 1px 9px -1px rgba(0,0,0,0.25), 0 6px 40px 4px rgba(0,0,0,0.20);
  transition: all .25s ease-in-out;
  transform: translateY(-14vh);
  opacity: 0;

  .icone {
    transition: transform 0.25s cubic-bezier(0.43, 0, 0.17, 1.4);
    float: right;
    max-width: 44px;
    max-height: 44px;

    &:hover, &:focus {
      transform: translateY(-4px);
    }
  }

  &.ouvrir {
    z-index: 5;
    opacity: 1;
    transform: translateY(-12vh);
  }
}

/* Carte */
/*--------------------------------------*/
@mixin carte() {
  border-radius: 2px;
  padding: 20px;
  background-color: $alabaster;
  background-image: url("../images/point.svg"),  url("../images/point.svg"),  url("../images/point.svg"),  url("../images/point.svg");
  background-position: 10px 10px, calc(100% - 10px) 10px, 10px calc(100% - 10px), calc(100% - 10px) calc(100% - 10px);
  background-repeat: no-repeat;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15), 0 2px 15px rgba(0,0,0,0.1);

  @media (min-width: $bureau) {
    padding: 20px 40px;
    height: fit-content;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15), 0 2px 30px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: $medium) {
    background-position: 20px 20px,calc(100% - 20px) 20px,20px calc(100% - 20px),calc(100% - 20px) calc(100% - 20px);
  }
}

.carte {
  @include carte();
}

/* Formulaire */
/*--------------------------------------*/
@mixin titreForm() {
  @include fontSize(14px);
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &:hover, &:focus {
    cursor: pointer;
  }
}