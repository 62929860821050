/**
 * @file Styles - Page services
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Page layout
 * Les 3 services de base
 * Études de cas
 * --- glide
 *
 */

// Page layout
// ------------------------------------------------------
.services {
  @media (min-width: $bureau) {
    margin: 8rem 0 0;
  }

  &__conteneur {
    @media (min-width: $bureau) {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      padding: 24px 0;
    }

    @media (min-width: $grand) {
      padding: 40px 0;
    }
  }

  // Les 3 services de base
  // ------------------------------------------------------
  &__item {
    margin: 20px 0;

    @media (min-width: $bureau) {
      margin-right: gutter(12);
      width: span(4 of 12);

      &:last-of-type {
        margin-right: 0;
      }

      &:nth-of-type(2) {
        order: -1;
      }
    }
  }

  &__flex {
    text-align: center;
  }

  &__svg {
    .icone {
      margin: 10px 0 0;
      width: 120px;
      height: 100px;
    }
  }

  &__titre {
    @media (max-width: $tablette) {
      @include fontSize(20px);
    }

    @media (min-width: $bureau) {
      @include fontSize(20px);
    }

    @media (min-width: $grand) {
      @include fontSize(28px);
    }
  }

  &__sousTitre {
    color: $orangeFonce;
    text-shadow: 0 0 1px $ocre;
    font-family: $fonteTerciaire;
    letter-spacing: .5px;
    font-weight: 300;
  }
}

// Études de cas
// ------------------------------------------------------
.etudeCas {
  padding-bottom: 8rem;

  @media (min-width: $grand) {
    padding-bottom: 0;
  }

  img {
    width: 100vw;
  }

  &__liste {
    transform: translateX(-2rem);
  }

  &__etape {
    padding: 6vw;
  }

  &__titre {
    @media (min-width: $grand) {
      @include fontSize(28px);
    }
  }

  &__sujet, &__titre {
    margin: 1rem 0;
  }

  &__sujet {
    color: $orangeFonce;
  }

  // Splide
  &__photos {
    position: relative;
    min-height: calc(100vw - 16px);
    pointer-events: none;

    @media (min-width: $bureau) {
      min-height: 56vw;
    }

    @media (min-width: $large) {
      min-height: 46vw;
      overflow: hidden;
    }

    .splide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }

    &_label {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10;
      padding: 10px 20px;
      background: $blanc;
      text-transform: uppercase;
      font-weight: bold;
      @include shadow--box();
    }
  }

  // inverser pour le SALON
  &.etudeCas--salon {
    .etudeCas__photos_label {
      right: initial;
      left: 20px;
    }

    .glide {
      margin: 0 0 0 auto;

      &__arrow, &__bullets {
        @media (min-width: $grand) {
          transform: translate(-550px, -300px);

          &--left {
            transform: translate(-550px, -300px) scaleX(-1);
          }
        }
      }
    }
  }

  // GLIDE //
  .glide {
    max-width: 600px;

    &__track {
      margin-top: -16vw;
    }

    &__arrow {
      bottom: -4rem;
      width: 50px;

      @media (min-width: $bureau) {
        width: 86px;
      }
    }

    &__bullets {
      bottom: -4rem;

      @media (min-width: $bureau) {
        bottom: -2rem;
      }
    }

    &__arrow, &__bullets {
      @media (min-width: $grand) {
        transform: translate(550px, -300px);

        &--left {
          transform: translate(550px, -300px) scaleX(-1);
        }
      }
    }
  }
}
