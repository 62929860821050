/**
 * @file Styles - Page contact
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Styles généraux
 * -- Présentation
 * -- Google map
 *
 * Présentation
 * -- Photo
 * -- Texte
 *
 * Fomulaire
 * -- Erreurs
 *
 * Modale de confirmation d'envoi
 */

// ------------------------------------------------------
// Styles généraux
// ------------------------------------------------------
.contact {
  // Présentation
  &__presentation {
    margin-bottom: 40px;

    @media (min-width: $bureau) {
      max-width: calc(100% - 430px);
      margin: 8rem 0 60px;
    }
  }
}

// Google map
.map {

}

// ------------------------------------------------------
// Formulaire
// ------------------------------------------------------
.formulaire {
  background: #FBFBFB;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15), 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 26px 20px;
  margin: -40vw auto 0;
  transform: translateY(40vw);

  input, textarea {
    transition: all .25s ease-in-out;
    @include shadow--input();
    background: #F9FAFB;
    border: 2px solid $blanc;
    border-radius: 2px;
    width: 100%;
    height: 38px;
    padding: 2px 6px;

    &:focus, &.erreur:focus {
      border-bottom: 3px solid $lilas;
    }
  }

  textarea {
    height: 140px;
    resize: vertical;
  }

  label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
  }

  .ctnForm {
    margin-bottom: 20px;
  }

  .icone--erreur {
    margin: 0 6px -2px 0;
    width: 20px;
    height: 20px;
  }

  @media (min-width: $tablette) {
    max-width: 380px;
    padding: 20px 20px 24px;
  }

  @media (min-width: $bureau) {
    width: span(6 of 12);
    position: absolute;
    top: 260px;
    right: 0;
    margin: 0 40px;
    transform: none;
  }

  @media (min-width: $grand) {
    top: 210px;
  }

  @media (min-width: 1280px) {
    margin: 0 10vw 0 0;
  }
}

/* Erreurs */
/*-----------------------------------*/
.formulaire input.erreur, .formulaire textarea.erreur {
  border-bottom: 3px solid $erreur;
  //padding: 2px 6px 5px;
}
.msgErreur {
  margin-top: .5rem;
  color: $erreur;
}


// ------------------------------------------------------
// Modale de confirmation d'envoi
// ------------------------------------------------------
.modaleCourriel, noscript {
  @extend .boiteModale;

  &__titre {
    margin: 0;
  }
}

// ------------------------------------------------------
// reCaptcha
// ------------------------------------------------------
.grecaptcha-badge {
  visibility: hidden;
}

// add this when it's working
//This site is protected by reCAPTCHA and the Google
//<a href="https://policies.google.com/privacy">Privacy Policy</a> and
//<a href="https://policies.google.com/terms">Terms of Service</a> apply.