/**
 * @file Styles - cta : Call To Action
 * @desc Pour la section "contactez-moi"
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Styles généraux
 * Décoraitons pour l'image
 *
 */


// Styles généraux
// ------------------------------------------------------
.cta {
  background: $orange;
  font-weight: 400;
  padding: 2rem 0 6px;
  overflow: hidden;

  @media (min-width: $bureau) {
    display: flex;
    padding: 0;
  }

  .conteneur {
    padding: 4vw 1rem 0rem;

    @media (min-width: $bureau) {
      padding: 6rem 0;
      transform: translateX(2rem);
    }

    @media (min-width: $grand) {
      transform: translateX(4rem);
    }

    @media (min-width: $large) {
      transform: translateX(6vw);
    }
  }

  &__titre {
    margin: 0;
    color: $noir;
  }

  &__flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__tel {
    &:after {
      bottom: 12px;
    }
  }

  .bouton {
    margin-right: 2vw;
    text-shadow: 0 0 1px $marine;

    @media (min-width: $mobile) {
      margin-right: 6vw;
    }

    @media (min-width: $bureau) {
      margin-right: 32px;
    }
  }

  &__info {
    @media (min-width: $bureau) {
      width: 75%;
    }

    @media (min-width: $large) {
      width: 80%;
    }
  }

  &__imgBG {
    position: relative;
    width: 100%;
    height: 75vw;
    background: url("../images/cta.png") no-repeat top center/ 220%;
    clip-path: polygon(0 20%, 100% 0%, 100% 80%, 0% 100%);

    @media (min-width: $bureau) and (max-width: $medium) {
      background-size: cover;
    }

    @media (min-width: $bureau) {
      width: 60%;
      height: initial;
      clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  // Décorations pour l'image
  // ------------------------------------------------------
  &__ligne {
    position: absolute;
    display: block;
    width: 100%;

    &--1 {
      height: calc(15vw + 20px);
      background: $marine;
      clip-path: polygon(0 0%, 100% 0%, 100% 20px, 0% 100%);

      @media (min-width: $bureau) {
        height: 100%;
        clip-path: polygon(0px 100%, 0% 0%, calc(40% + 40px) 0px, 40px 100%);
      }
    }

    &--2 {
      top: 50px;
      height: calc(15vw + 10px);
      background: $orange;
      clip-path: polygon(0 calc(100% - 10px), 100% 0%, 100% 10px, 0% 100%);

      @media (min-width: $bureau) {
        top: initial;
        left: 80px;
        height: 100%;
        clip-path: polygon(0px 100%, 40% 0%, calc(40% + 10px) 0px, 10px 100%);
      }
    }

    &--3 {
      top: 60px;
      height: calc(15vw + 8px);
      background: $marine;
      clip-path: polygon(0 calc(100% - 8px), 100% 0%, 100% 8px, 0% 100%);

      @media (min-width: $bureau) {
        top: initial;
        left: 90px;
        height: 100%;
        clip-path: polygon(0px 100%, 40% 0%, calc(40% + 20px) 0px, 20px 100%);
      }
    }
  }

  // Spécifiques pour la page de contact
  &--contact {
    .cta {
      &__flex {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        @media (min-width: $grand) {
          grid-template-columns: 1fr 1fr;
        }
      }

      &__tel {
        margin: 0 10px;
      }
    }

    .carte {
      width: fit-content;
    }
  }
}