/**
 * @file Styles des fontes
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ---------------------------------------------------------
 * TABLE DES MATIÈRES - Typographie responsive (Mobile First)
 * ---------------------------------------------------------
 * Base décimale
 * Hiérarchie responsive
 */


/** Base décimale **/
html{
  font-size: 62.5%;
}

body, .p {
  @include fontSize(14px);
  line-height: 1.65;
  font-family: $fontePrimaire;
  font-weight: 400;
  letter-spacing: .15px;
  color: $marine;

  @media (min-width: $tablette) {
    @include fontSize(16px);

  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: $fonteTerciaire;
  font-weight: bold;
  letter-spacing: .75px;
  line-height: 1.4;
}


/** Hiérarchie responsive **/
/* TITRES NIVEAU 1 */
h1,
.h1 {
  font-family: $fonteSecondaire;
  text-transform: uppercase;
  @include fontSize(32px);

  @media (min-width: $bureau) {
    @include fontSize(48px);
  }

  @media (min-width: $large) {
    @include fontSize(68px);
  }
}


/* TITRES NIVEAU 2 */
h2,
.h2 {
  @include fontSize(24px);

  @media (min-width: $bureau) {
    @include fontSize(28px);
  }

  @media (min-width: $large) {
    @include fontSize(48px);
  }
}


/* TITRES NIVEAU 3 */
h3,
.h3 {
  @include fontSize(16px);

  @media (min-width: $bureau) {
    @include fontSize(18px);
  }

  @media (min-width: $large) {
    @include fontSize(20px);
  }
}


/* TITRES NIVEAU 4 */
h4,
.h4 {
  @include fontSize(18px);
  font-weight: 500;

  @media (min-width: $bureau) {
    @include fontSize(18px);
  }

  @media (min-width: $large) {
    @include fontSize(20px);
  }
}

.desc {
  @include fontSize(12px);

  @media (min-width: $tablette) {
    @include fontSize(14px);
  }

  @media (min-width: $bureau) {
    @include fontSize(16px);
  }
}