/**
 * @file Styles du pied de page
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ---------------------------------------------------------
 * TABLE DES MATIÈRES - Pied de page
 * ---------------------------------------------------------
 * STYLES GÉNÉRAUX
 * MENU
 * COPYRIGHTS
 * SOCIAUX
 * Boite modale
 */

/*-----------------------------------*/
/* STYLES GÉNÉRAUX */
/*-----------------------------------*/
.footer {
  background: $marine;
  color: $blanc;

  @media (max-width: $bureau) {
    position: fixed;
    bottom: 0;
    z-index: -1;
    padding: 50vh 0 2rem;
    width: 100%;
  }

  @media (min-width: $bureau) {
    padding: 4rem 6rem 4rem 4rem;
  }

  @media (min-width: $large) {
    padding: 6rem 8rem 6rem 6rem;
  }

  &__conteneur {
    @media (min-width: $bureau) {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
    }

    @media (min-width: $bureau) {
      align-items: flex-end;
    }
  }


  .logo {
    @media (min-width: $bureau) {
      grid-area: 1 / 1 / 3 / 2;
    }

    &__img {
      display: block;
      margin: 0 auto;
      max-width: 280px;

      @media (min-width: $bureau) {
        margin: 0;
        max-width: 100px;
      }

      @media (min-width: $medium) {
        max-width: 150px;
      }
    }

    &__tx {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: $blanc;
      @include fontSize(22px);
      line-height: 0.75;
      font-family: $fonteSecondaire;
      text-transform: uppercase;
      font-weight: 600;

      @media (min-width: $bureau) {
        grid-area: 1 / 2 / 2 / 3;
        align-items: flex-start;
      }

      @media (min-width: $medium) {
        @include fontSize(28px);
      }

      .haut, .bas {
        margin: 2rem 0 0;

        @media (min-width: $bureau) {
          margin: 0;
        }
      }

      .haut {
        @media (max-width: $bureau) {
          @include fontSize(29px);
        }

        @media (min-width: $bureau) {
          width: max-content;
        }

        @media (min-width: $medium) {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            bottom: -26px;
            left: 0;
            width: 100%;
            height: 2px;
            background: $blanc;
          }
        }
      }
    }
  }

  // Pour le logo de Carrelage pro-expert
  //.logo {
  //  &__img {
  //    display: block;
  //    margin: 0 auto;
  //    max-height: 120px;
  //  }
  //
  //  &__tx {
  //    display: flex;
  //    flex-flow: column nowrap;
  //    justify-content: center;
  //    align-items: center;
  //    color: $blanc;
  //    font-family: $fonteTerciaire;
  //    letter-spacing: 5px;
  //    text-transform: uppercase;
  //
  //    &_haut {
  //      font-weight: 500;
  //      @include fontSize(38px);
  //      margin: 24px 0 0;
  //      color: $txLight;
  //    }
  //
  //    &_mid {
  //      @include fontSize(18px);
  //      margin: -5px 0 20px;
  //      color: $txGray;
  //    }
  //
  //    &_bas {
  //      @include fontSize(12px);
  //      color: $txLight;
  //    }
  //  }
  //}



  /*-----------------------------------*/
  /* MENU */
  /*-----------------------------------*/
  .menu {
    @media (min-width: $bureau) {
      grid-area: 2 / 2 / 3 / 3;
      opacity: .8;
    }

    &__conteneur {
      @media (min-width: $bureau) {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-rows: auto auto;
        align-items: flex-end;
        gap: 10px 20px;
        max-width: 540px;
      }

      @media (min-width: $medium) {
        grid-template-columns: 150px 1fr;
        gap: 10px 40px;
        max-width: 725px;
      }
    }

    &__liste {
      @media (min-width: $bureau) {
        flex-flow: row nowrap;
        justify-content: space-between;
        border: none;
        padding: 0;
        box-shadow: none;

        // temp
        //gap: 30px;
        //justify-content: flex-start;
      }
    }

    &__item_lien {
      @media (min-width: $bureau) {
        padding: 0;
        transform: translateY(4px);
      }

      @media (min-width: $medium) {
        padding: 20px 0 0;
        transform: none;
      }
    }
  }


  /*-----------------------------------*/
  /* SOCIALS */
  /*-----------------------------------*/
  .subnav__liste {
    margin: 3rem 0 2rem;
    justify-content: center;

    @media (min-width: $bureau) {
      margin: 0 0 2rem;
      justify-content: flex-end;
      transform: translateX(10px);
    }
  }

  /*-----------------------------------*/
  /* COPYRIGHTS */
  /*-----------------------------------*/
  &__copyrights {
    text-align: center;
    display: block;

    @media (min-width: $bureau) {
      text-align: right;
    }
  }

  /*-----------------------------------*/
  /* Boite modale */
  /*-----------------------------------*/
  &__modale {
    @extend .boiteModale;

    .h2 {
      margin: 0;
    }
    ul {
      margin-bottom: 0;
    }
  }
}