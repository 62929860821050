/**
 * @file Styles de la page À Propos
 * @author Christine Daneau-Pelletier <christine.daneau.pelletier@gmail.com>
**/

/**
 * ---------------------------------------------------------
 * TABLE DES MATIÈRES
 * ---------------------------------------------------------
 * Sections
 * --- carte
 * --- Le reste du contenu
 *
 * Présentation
 *
 */


/* Sections - Styles communs */
/*-----------------------------------*/
.apropos {
  &__section {
    position: relative;
    background: $wildSand;

    &:nth-of-type(odd) {
      background: $marine;

      .apropos__2ndContent {
        color: $blanc;
      }
    }
  }

  &__flex {
    @media (min-width: $medium) {
      display: flex;
    }
  }

  // Carte
  .carte {
    margin: -140px 0 8vw;
    position: relative;

    @media (min-width: $medium) {
      margin: -170px 0 6rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 2px;
      background: $blanc;
      z-index: -1;
    }

    @media (min-width: $medium) {
      max-width: 50%;
      padding-bottom: 40px;
    }
  }

  // Le reste du contenu
  &__2ndContent {
    p, h3 {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &_contenu {
      padding: 0 0 8vw;

      @media (min-width: $medium) {
        padding: 6rem 0 6rem 6rem;
      }
    }
  }
}

/* Présentation */
/*-----------------------------------*/
.presentation {
  padding: 0 0 4rem;

  @media (min-width: $bureau) {
    padding: 8rem 0;
    display: grid;
    grid-template-columns: 50% 1fr;
    gap: 32px;

    .titrePage {
      margin: 0 0 40px;
    }
  }

  &__picture {
    @media (min-width: $bureau) {
      text-align: right;
    }
  }

  &__img {
    margin-bottom: 4rem;

    @media (min-width: $bureau) {
      position: sticky;
      top: 4rem;
      max-width: 480px;
      margin-bottom: 0;
    }
  }
}